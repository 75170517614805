import "bootstrap/dist/css/bootstrap.css";
import { Link } from "wouter";
import { RouteKind, serializeRoute } from "./route";
import { getProtocol } from "./api/protocol";
import { useEffect, useState } from "react";
import { Protocol } from "./types";

type ProtocolId = number;

type ProtocolProps = {
  protocolId: ProtocolId;
};

export function ProtocolViewElement({ protocolId }: ProtocolProps) {
  const [protocol, setProtocol] = useState<Protocol | null>(null);
  const [showNotFound, setShowNotFound] = useState<boolean>(false);

  useEffect(() => {
    (async function () {
      try {
        const protocol = await getProtocol(protocolId);
        if (protocol == null) {
          setShowNotFound(true);
        }
        setProtocol(protocol);
      } catch (e) {
        console.error(e);
        setShowNotFound(true);
      }
    })();
  }, [protocolId]);

  if (showNotFound) {
    const main: React.CSSProperties = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      flexDirection: "column",
    };
    return (
      <div style={main}>
        <h1>Protokoll nicht gefunden</h1>
        <Link href={serializeRoute({ kind: RouteKind.Home })}>zurück</Link>
      </div>
    );
  }

  if (protocol == null) {
    return <div className="spinner-border" role="status"></div>;
  }

  const formStyles = {
    container: {
      fontFamily: "Arial, sans-serif",
      padding: "20px",
      maxWidth: "900px",
      margin: "0 auto",
      border: "1px solid #ccc",
      borderRadius: "10px",
    },
    title: {
      fontSize: "24px",
      fontWeight: "bold",
      marginBottom: "20px",
      textAlign: "center" as "center",
    },
    subtitle: {
      fontSize: "16px",
      marginBottom: "10px",
    },
    section: {
      marginBottom: "20px",
    },
    label: {
      display: "block",
      marginBottom: "5px",
      fontWeight: "bold",
    },
    input: {
      width: "100%",
      padding: "10px",
      marginBottom: "10px",
      border: "1px solid #ccc",
      borderRadius: "5px",
    },
    table: {
      width: "100%",
      borderCollapse: "collapse" as "collapse",
    },
    tableHeader: {
      backgroundColor: "#f2f2f2",
      fontWeight: "bold",
    },
    tableCell: {
      border: "1px solid #ccc",
      padding: "10px",
      textAlign: "center" as "center",
    },
    signatureSection: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    signature: {
      borderTop: "1px solid #000",
      width: "45%",
      textAlign: "center" as "center",
      paddingTop: "10px",
    },
  };

  return (
    <main>
      <div style={formStyles.container}>
        <div style={formStyles.title}>Protokoll - Haltverbot</div>
        <div style={formStyles.subtitle}>
          gem. Ziffer 6.1 Abs. 6 der ZTV-SA 97
        </div>

        <div style={formStyles.section}>
          <label style={formStyles.label}>Adresse</label>
          <input
            type="text"
            style={formStyles.input}
            value={protocol.address ?? ""}
            disabled
          />
        </div>

        <div style={formStyles.section}>
          <label style={formStyles.label}>Aufgestellt am</label>
          <input
            type="text"
            style={formStyles.input}
            value={protocol.date ?? ""}
            disabled
          />
        </div>

        <div style={formStyles.section}>
          <table style={formStyles.table}>
            <thead>
              <tr style={formStyles.tableHeader}>
                <th style={formStyles.tableCell}>Kennzeichen</th>
                <th style={formStyles.tableCell}>Farbe</th>
                <th style={formStyles.tableCell}>Fabrikat</th>
              </tr>
            </thead>
            <tbody>
              {Array.from(Object.entries(protocol.carInfos)).map(
                ([id, carInfo]) => (
                  <tr key={id}>
                    <td style={formStyles.tableCell}>{carInfo.licensePlate}</td>
                    <td style={formStyles.tableCell}>{carInfo.color}</td>
                    <td style={formStyles.tableCell}>{carInfo.brand}</td>
                  </tr>
                ),
              )}
            </tbody>
          </table>
        </div>

        <div style={formStyles.signatureSection}>
          <div>
            <label style={formStyles.label}>Verantwortlicher</label>
            <input
              type="text"
              style={formStyles.input}
              placeholder="Name, Vorname"
              value={protocol.recorderName ?? ""}
              disabled
            />
          </div>
        </div>
      </div>
    </main>
  );
}
