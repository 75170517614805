import { useEffect, useState } from "react";
import { fetchProtocolSummaries } from "./api/protocol";
import { postLinkQrCode } from "./api/qr-code";
import { ProtocolId, ProtocolSummary } from "./types";

export type LinkQrCodeElementProps = {
  qrCodeUuid: string;
};

enum SubmitState {
  SUBMITTING,
  SUBMITTED,
  FAILED,
}

export function LinkQrCodeElement({ qrCodeUuid }: LinkQrCodeElementProps) {
  const [protocolSummaries, setProtocolSummaries] = useState<null | Map<
    ProtocolId,
    ProtocolSummary
  >>(null);
  const [fetchFailed, setFetchFailed] = useState(false);

  const showLoadingSpinner = protocolSummaries == null && !fetchFailed;

  const [selectedProtocol, setSelectedProtocol] = useState<ProtocolId | null>(
    null,
  );

  const [submitState, setSubmitState] = useState<SubmitState | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const summaries = await fetchProtocolSummaries();
        setProtocolSummaries(await fetchProtocolSummaries());
        if (summaries.size > 0) {
          const protocolId = Array.from(summaries)[0][0];
          setSelectedProtocol(protocolId);
        }
        setFetchFailed(false);
      } catch (err) {
        console.error("Failed to fetch protocol summaries on home screen", err);
        setFetchFailed(true);
      }
    })();
  }, []);

  function handleChange(event: React.ChangeEvent<HTMLSelectElement>) {
    if (event.target.value === "") {
      setSelectedProtocol(null);
      return;
    }
    const protocolId = parseInt(event.target.value);
    setSelectedProtocol(protocolId);
  }

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (selectedProtocol == null) {
      setSubmitState(SubmitState.FAILED);
      return;
    }

    setSubmitState(SubmitState.SUBMITTING);
    try {
      await postLinkQrCode(qrCodeUuid, selectedProtocol);
      setSubmitState(SubmitState.SUBMITTED);
    } catch (err) {
      console.error("Failed to link QR code", err);
      setSubmitState(SubmitState.FAILED);
    }
  }

  return (
    <main style={{ margin: 8 }}>
      <h1>QR-Code mit Protokoll verbinden</h1>

      {showLoadingSpinner && (
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      )}

      {fetchFailed && (
        <div className="alert alert-danger" role="alert">
          Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.
        </div>
      )}

      {protocolSummaries != null && (
        <form
          style={{ display: "flex", flexDirection: "column", gap: 16 }}
          onSubmit={handleSubmit}
        >
          <label>
            Wählen Sie das Protokoll, das Sie mit dem QR-Code verbinden möchten:
            <select
              className="form-select"
              value={selectedProtocol ?? undefined}
              required
              onChange={handleChange}
            >
              {Array.from(protocolSummaries).map(
                ([protocolId, protocolSummary]) => (
                  <option key={protocolId} value={protocolId}>
                    {protocolSummary.address},{" "}
                    {(() => {
                      const monthName = new Date(protocolSummary.date)
                        .toLocaleString("de-DE", { month: "long" })
                        .slice(0, 3);
                      const day = new Date(protocolSummary.date).getDate();
                      return `${day}. ${monthName}`;
                    })()}
                    , {protocolSummary.recorderName}
                  </option>
                ),
              )}
            </select>
          </label>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <input
              type="submit"
              value="QR-Code verbinden"
              className="btn btn-dark"
              disabled={
                submitState === SubmitState.SUBMITTING ||
                selectedProtocol == null
              }
            />
          </div>
          {(() => {
            switch (submitState) {
              case null:
                return null;
              case SubmitState.SUBMITTING:
                return <div className="spinner-border"></div>;
              case SubmitState.SUBMITTED:
                return (
                  <div className="alert alert-success" role="alert">
                    Der QR-Code wurde erfolgreich mit dem Protokoll verbunden.
                  </div>
                );
              case SubmitState.FAILED:
                return (
                  <div className="alert alert-danger" role="alert">
                    Ein Fehler ist aufgetreten. Bitte versuchen Sie es später
                    erneut.
                  </div>
                );
              default: {
                const exhaustive: never = submitState;
                throw new Error(`Unhandled submit state: ${exhaustive}`);
              }
            }
          })()}
        </form>
      )}
    </main>
  );
}
