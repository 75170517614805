import { useCookies } from "react-cookie";
import { Link, useLocation } from "wouter";
import { serializeRoute, RouteKind } from "./route";

function Navbar() {
  const [cookies] = useCookies(["user"]);
  const [, setLocation] = useLocation();

  async function signout() {
    let response = await fetch("/api/signout", {
      method: "POST",
    });
    if (response.status !== 200 /* OK */) {
      throw new Error(`Signout failed: ${response}`);
    }
    // TODO: Make specialized signout page.
    setLocation(serializeRoute({ kind: RouteKind.Home }));
  }

  const sign_in_out = cookies.user ? (
    <button className="btn btn-sm btn-secondary" onClick={signout}>
      Abmelden
    </button>
  ) : (
    <Link
      href={serializeRoute({ kind: RouteKind.Signin })}
      className="btn btn-secondary"
    >
      Anmelden
    </Link>
  );

  return (
    <nav className="navbar navbar-light bg-light static-top">
      <div className="container">
        <a
          className="navbar-brand"
          href={serializeRoute({ kind: RouteKind.Home })}
        >
          Aufstellprotokoll
        </a>
        {sign_in_out}
      </div>
    </nav>
  );
}

export default Navbar;
