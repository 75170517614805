import { Protocol, ProtocolId, ProtocolSummary } from "../types";

export async function getProtocol(protocolId: ProtocolId): Promise<Protocol> {
  const response = await fetch(`/api/protocol/${protocolId}`);

  if (!response.ok) {
    throw new Error(`Failed to fetch protocol: ${protocolId}`);
  }

  return response.json();
}

export async function postProtocol(
  protocolId: ProtocolId,
  protocol: Protocol,
): Promise<void> {
  console.log(protocol);
  const response = await fetch(`/api/protocol/${protocolId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(protocol),
  });

  if (!response.ok) {
    throw new Error(`Failed to post protocol metadata: ${protocolId}`);
  }
}

export type GeoLocation = {
  latitude: number;
  longitude: number;
  accuracy: number;
};

type CreateProtocolPayload = {
  geoLocation: GeoLocation | null;
};

export async function createProtocol(
  geoLocation: GeoLocation | null,
): Promise<ProtocolId> {
  const payload: CreateProtocolPayload = {
    geoLocation,
  };
  const response = await fetch(`/api/protocol`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });

  if (!response.ok) {
    throw new Error("Failed to create protocol");
  }

  return response.json();
}

export async function fetchProtocolSummaries(): Promise<
  Map<ProtocolId, ProtocolSummary>
> {
  const response = await fetch(`/api/protocol`);

  if (!response.ok) {
    throw new Error("Failed to create protocol");
  }

  const entries = Object.entries(await response.json());
  return new Map(
    entries.map(([id, summary]) => {
      return [parseInt(id), summary as ProtocolSummary];
    }),
  );
}

export function downloadLinkPdf(protocolId: ProtocolId): string {
  return `/api/protocol/${protocolId}/download/pdf`;
}

export function downloadLinkHtml(protocolId: ProtocolId): string {
  return `/api/protocol/${protocolId}/download/html`;
}

export async function postProtocolOrderNumber(
  protocolId: ProtocolId,
  orderNumber: string,
) {
  const response = await fetch(`/api/protocol/${protocolId}/order-number`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(orderNumber),
  });

  if (!response.ok) {
    throw new Error("Failed to post order number");
  }
}
