import jsQR from "jsqr";
import { useEffect, useRef, useState } from "react";

type ProtocolEditElementProps = {
  onScanned: (data: string) => void;
};

export function QRCodeScanElement({
  onScanned,
}: ProtocolEditElementProps): JSX.Element {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      const video = videoRef.current;
      if (video == null) {
        throw new Error("Video element should always be rendered");
      }

      // Create a canvas element to capture video frames
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      if (canvas.width == 0 || canvas.height == 0) {
        return;
      }

      const ctx = canvas.getContext("2d");
      if (ctx == null) {
        throw new Error("Failed to get 2d context");
      }

      // Draw the current video frame onto the canvas
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      // Get the image data from the canvas
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

      // Scan the image data for QR codes using jsQR
      const code = jsQR(imageData.data, imageData.width, imageData.height);

      if (code) {
        onScanned(code.data);
      }
    }, 1000 / 6);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    let stream: MediaStream | null = null;

    (async () => {
      const video = videoRef.current;
      if (video == null) {
        throw new Error("Video element should always be rendered");
      }

      const constraints = {
        video: {
          facingMode: { exact: "environment" },
        },
      };
      stream = await navigator.mediaDevices.getUserMedia(constraints);
      video.srcObject = stream;
    })();

    return () => {
      if (stream != null) {
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);

  return (
    <video
      ref={videoRef}
      preload="none"
      id="video"
      autoPlay
      playsInline
    ></video>
  );
}
