import { qrCodeDownloadLink } from "./api/qr-code";

enum Browser {
  IOS = "IOS",
  ANDROID_CHROME = "ANDROID_CHROME",
  OTHER = "OTHER",
}

function detectBrowser(): Browser {
  const userAgent = navigator.userAgent || navigator.vendor || "";

  // Detect iOS
  if (/iPad|iPhone|iPod/.test(userAgent) && "MSStream" in window) {
    return Browser.IOS;
  }

  // Detect Android Chrome
  if (/android/i.test(userAgent) && /chrome/i.test(userAgent)) {
    return Browser.ANDROID_CHROME;
  }

  // Default case
  return Browser.OTHER;
}

export function QrCodePrintElement() {
  const browser = detectBrowser();
  switch (browser) {
    case Browser.IOS:
    case Browser.ANDROID_CHROME:
      return (
        <a className="btn btn-dark" href={qrCodeDownloadLink(10)} download>
          QR Codes drucken
        </a>
      );
    case Browser.OTHER:
      return (
        <button
          className="btn btn-dark"
          onClick={(ev) => {
            const iframe = document.createElement("iframe");
            iframe.style.position = "absolute";
            iframe.style.width = "0px";
            iframe.style.height = "0px";
            iframe.src = qrCodeDownloadLink(10);
            document.body.appendChild(iframe);

            iframe.onload = () => {
              iframe.contentWindow?.print();
            };
          }}
        >
          QR Codes drucken
        </button>
      );
    default: {
      const exhaustive: never = browser;
      throw new Error(`Unhandled: ${exhaustive}`);
    }
  }
}
