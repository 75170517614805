import { ProtocolId } from "../types";

export function qrCodeDownloadLink(pageNum: number): string {
  return `/api/qr-code?page_num=${pageNum}`;
}

type PostLinkQrCodeRequestPayload = {
  protocolId: ProtocolId;
};

export async function postLinkQrCode(
  qrCodeUuid: string,
  protocolId: ProtocolId,
): Promise<void> {
  const payload: PostLinkQrCodeRequestPayload = { protocolId };
  const response = await fetch(`/api/qr-code/${qrCodeUuid}/link`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });

  if (!response.ok) {
    throw new Error(`Failed to link QR code: ${response.statusText}`);
  }
}
