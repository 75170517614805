export function Agb() {
  return (
    <main className="row justify-content-center">
      <div className="col-xl-6">
        <div style={{ padding: 8 }}>
          <h1>
            Allgemeine Geschäftsbedingungen (AGB) für aufstellprotokoll.de
          </h1>

          <h2>Geltungsbereich</h2>

          <p>
            Diese Allgemeinen Geschäftsbedingungen (AGB) regeln das Verhältnis
            zwischen dem Nutzer und "aufstellprotokoll.de", einem Webservice zur
            Analyse von Dokumenten auf potenziell steuerlich absetzbare
            Ausgaben.
          </p>

          <h2>Zulässige Inhalte</h2>

          <p>
            Der Nutzer verpflichtet sich, nur legale Inhalte auf
            "aufstellprotokoll.de" hochzuladen. Ausgeschlossen sind Dokumente
            mit urheberrechtsverletzenden, verleumderischen, diffamierenden,
            pornografischen, gewaltverherrlichenden oder anderweitig
            rechtswidrigen Inhalten.
          </p>

          <h2>Datenschutz und Datenverarbeitung</h2>

          <p>
            Der Nutzer behält alle Rechte an den hochgeladenen Daten. Er kann
            jederzeit die Löschung seiner Daten verlangen. aufstellprotokoll.de
            verpflichtet sich, solchen Anfragen unverzüglich nachzukommen und
            die betreffenden Daten dauerhaft und vollständig zu löschen.
          </p>
          <p>
            Nutzerdaten werden auf Servern in Deutschland gespeichert und für
            Analysezwecke temporär an Server von OpenAI Ireland Ltd übermittelt.
            OpenAI Ireland Ltd gibt an, Nutzerdaten maximal 30 Tage zu
            speichern.
          </p>
          <p>
            aufstellprotokoll.de trifft angemessene Maßnahmen, um die Sicherheit
            und Vertraulichkeit der Nutzerdaten zu gewährleisten.
          </p>

          <h2>Haftungsausschluss</h2>

          <p>
            aufstellprotokoll.de stellt lediglich ein Werkzeug zur Analyse von
            Dokumenten bereit und übernimmt keine Haftung für die Richtigkeit,
            Vollständigkeit oder Zuverlässigkeit der Analyseergebnisse. Die
            Nutzer sind selbst verantwortlich für die Überprüfung der
            Ergebnisse, insbesondere vor der Verwendung für die Steuererklärung
            oder andere rechtliche Zwecke.
          </p>

          <h2>Schlussbestimmungen</h2>

          <p>
            Änderungen dieser AGB bedürfen der Schriftform. Mündliche
            Nebenabreden existieren nicht. Sollte eine Bestimmung dieser AGB
            unwirksam sein oder werden, bleibt die Wirksamkeit der übrigen
            Bestimmungen unberührt.
          </p>
        </div>
      </div>
    </main>
  );
}
