import { Car, CarId, ProtocolId } from "../types";

export async function postCar(
  protocolId: ProtocolId,
  carId: CarId,
  car: Car,
): Promise<[CarId, Car]> {
  const response = await fetch(`/api/protocol/${protocolId}/car/${carId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(car),
  });

  if (!response.ok) {
    throw new Error("Failed to post car");
  }

  return response.json();
}

export async function deleteCar(
  protocolId: ProtocolId,
  carId: CarId,
): Promise<void> {
  const response = await fetch(`/api/protocol/${protocolId}/car/${carId}`, {
    method: "DELETE",
  });

  if (!response.ok) {
    throw new Error("Failed to post car");
  }
}
