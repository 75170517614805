import "bootstrap/dist/css/bootstrap.css";
import "./landing.css";
import showcaseHandsInControl from "./placeholder.jpg";
import showcaseHandsOverBook from "./placeholder.jpg";
import showcaseDocumentPile from "./placeholder.jpg";
import { Link } from "wouter";
import { RouteKind, serializeRoute } from "./route";
import bgMasthead from "./placeholder.jpg";
import guyWithDocumentsGetsRobotHelp from "./placeholder.jpg";
import relaxedGuy from "./placeholder.jpg";
//import { LandingPageDemo } from "./landing-page-demo";

const imageShowCasesFirst = (
  <section className="showcase">
    <div
      className="container-fluid p-0"
      style={{ display: "flex", flexDirection: "column", gap: 32 }}
    >
      <div className="row g-0">
        <div
          className="col-lg-6 order-lg-2"
          style={{
            paddingLeft: 8,
            paddingRight: 8,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 16,
          }}
        >
          <h2>Digitale Baustellensicherung leicht gemacht</h2>
          <p className="lead mb-0">
            Mit Aufstellprotokoll.de erstellen Sie das Protokoll der
            Baustellensicherung bequem mit Ihrem Smartphone. Fotografieren Sie
            einfach die Autos vor Ort. Unsere intelligente AI übernimmt dann den
            Rest für Sie.
          </p>
        </div>
        <div
          className="col-lg-6 order-lg-1"
          style={{ paddingLeft: 8, paddingRight: 8 }}
        >
          <div
            className="showcase-img"
            style={{ backgroundImage: `url(${showcaseHandsOverBook})` }}
          ></div>
        </div>
      </div>
      <div className="row g-0">
        <div
          className="col-lg-6 order-lg-1"
          style={{
            paddingLeft: 8,
            paddingRight: 8,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 16,
          }}
        >
          <h2>Aufstellprotokoll automatisch ausfüllen</h2>
          <p className="lead mb-0">
            Unsere KI-basierte Bilderkennung füllt das Aufstellprotokoll
            automatisch für Sie aus. Sie müssen nur noch kontrollieren und
            absenden. So sparen Sie viel Zeit und Nerven bei der lästigen
            Bürokratie.
          </p>
        </div>
        <div
          className="col-lg-6 order-lg-2"
          style={{ paddingLeft: 8, paddingRight: 8 }}
        >
          <div
            className="showcase-img"
            style={{ backgroundImage: `url(${relaxedGuy})` }}
          ></div>
        </div>
      </div>
    </div>
  </section>
);

const imageShowCasesSecond = (
  <section className="showcase">
    <div
      className="container-fluid p-0"
      style={{ display: "flex", flexDirection: "column", gap: 32 }}
    >
      <div className="row g-0">
        <div
          className="col-lg-6 order-lg-2"
          style={{
            paddingLeft: 8,
            paddingRight: 8,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 16,
          }}
        >
          <h2>Fotodokumentation Ihrer Baustelle</h2>
          <p className="lead mb-0">
            Laden Sie mit Aufstellprotokoll.de ganz einfach Bilder von der
            Baustellensicherung hoch. So haben Sie immer eine lückenlose
            Dokumentation parat, falls es zu Schäden oder Streitigkeiten kommen
            sollte.
          </p>
        </div>
        <div
          className="col-lg-6 order-lg-1"
          style={{ paddingLeft: 8, paddingRight: 8 }}
        >
          <div
            className="showcase-img"
            style={{ backgroundImage: `url(${showcaseDocumentPile})` }}
          ></div>
        </div>
      </div>
      <div className="row g-0">
        <div
          className="col-lg-6 order-lg-1"
          style={{
            paddingLeft: 8,
            paddingRight: 8,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 16,
          }}
        >
          <h2>Sicherheit und Datenschutz</h2>

          <p className="lead mb-0">
            Der Schutz Ihrer Daten hat für uns höchste Priorität. Alle
            hochgeladenen Bilder und Protokolle werden auf deutschen Servern
            gespeichert und selbstverständlich DSGVO-konform behandelt.
          </p>
        </div>
        <div
          className="col-lg-6 order-lg-2"
          style={{ paddingLeft: 8, paddingRight: 8 }}
        >
          <div
            className="showcase-img"
            style={{ backgroundImage: `url(${showcaseHandsInControl})` }}
          ></div>
        </div>
      </div>
    </div>
  </section>
);

function HeadlineAndDemo(): JSX.Element {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        maxWidth: "min(100%, 1024px)",
      }}
    >
      <div
        className="container-fluid p-0"
        style={{ display: "flex", flexDirection: "column", gap: 32 }}
      >
        <div className="row g-0">
          <div
            className="col-lg-6 order-lg-1"
            style={{
              paddingLeft: 8,
              paddingRight: 8,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: 16,
            }}
          >
            <h1 style={{ fontSize: "3rem", fontWeight: "500" }}>
              Aufstellprotokoll einfach digital erstellen
            </h1>
            <p style={{ fontSize: "1.5rem" }}>
              Mit Aufstellprotokoll.de dokumentieren Sie die Baustellensicherung
              bequem per Smartphone. Unsere KI übernimmt die Bürokratie für Sie.
            </p>
            <p>
              <Link
                href={serializeRoute({
                  kind: RouteKind.Signup,
                  defaultEmail: null,
                })}
                className="btn btn-dark rounded-pill"
                style={{
                  fontSize: "2rem",
                  paddingLeft: "2rem",
                  paddingRight: "2rem",
                }}
              >
                Kostenlos Testen
              </Link>
            </p>
          </div>
          <div
            className="col-lg-6 order-lg-2"
            style={{ paddingLeft: 8, paddingRight: 8 }}
          >
            <img style={{ maxWidth: 200 }} src={bgMasthead} alt="TODO"></img>
            {/*
            <LandingPageDemo />
            */}
          </div>
        </div>
      </div>
    </div>
  );
}

function UserStory(): JSX.Element {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#e3d5ba",
        paddingTop: 32,
        paddingBottom: 32,
        paddingLeft: 8,
        paddingRight: 8,
        width: "100%",
      }}
    >
      <div
        style={{
          maxWidth: 1024,
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          fontSize: "1.5rem",
          alignItems: "center",
        }}
      >
        <p>
          Das Aufstellen der Baustellensicherung nimmt viel Zeit in Anspruch und
          muss penibel dokumentiert werden. Dabei drängt oft die Zeit, um die
          Baustelle pünktlich freizugeben.
        </p>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            overflow: "hidden",
          }}
        >
          <img
            src={guyWithDocumentsGetsRobotHelp}
            alt="TODO"
            style={{ width: "max(500px, 80%)", height: "auto" }}
          />
        </div>

        <p>
          Mit Aufstellprotokoll.de ersparen Sie sich viel Arbeit bei der
          Dokumentation. Machen Sie einfach Fotos mit Ihrem Smartphone. Unsere
          KI erstellt daraus automatisch das Protokoll für Sie. So haben Sie
          eine lückenlose, rechtssichere Dokumentation ohne viel Zeitaufwand.
        </p>
      </div>
    </div>
  );
}

function HowItWorks(): JSX.Element {
  return (
    <div
      style={{
        fontSize: "1.5rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#f8f4ee",
        paddingTop: 32,
        paddingBottom: 32,
        paddingLeft: 8,
        paddingRight: 8,
        width: "100%",
      }}
    >
      <div
        style={{
          maxWidth: 1024,
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          fontSize: "1.5rem",
          alignItems: "center",
        }}
      >
        <h2>So funktioniert es</h2>

        <ol>
          <li>
            <strong>Anmelden:</strong> Registrieren Sie sich kostenlos bei
            Aufstellprotokoll.de.
          </li>
          <li>
            <strong>Fotografieren:</strong> Machen Sie mit Ihrem Smartphone
            Fotos von den Autos an der gesicherten Baustelle.
          </li>
          <li>
            <strong>Analysieren:</strong> Unsere intelligente KI erkennt die
            Fahrzeuge und füllt automatisch das Aufstellprotokoll für Sie aus.
          </li>
          <li>
            <strong>Freigeben:</strong> Kontrollieren Sie das fertige Protokoll
            und geben Sie es mit nur einem Klick frei. Fertig!
          </li>
        </ol>
      </div>
    </div>
  );
}

function Conclusion(): JSX.Element {
  return (
    <div
      style={{
        fontSize: "1.5rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#f8f4ee",
        paddingTop: 32,
        paddingBottom: 32,
        paddingLeft: 8,
        paddingRight: 8,
        width: "100%",
      }}
    >
      <div
        style={{
          maxWidth: 1024,
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          fontSize: "1.5rem",
          alignItems: "center",
        }}
      >
        <h2>Dokumentieren Sie Baustellen digital mit Aufstellprotokoll.de</h2>

        <p>Sparen Sie Zeit und Nerven bei der Baustellensicherung.</p>
        <Link
          href={serializeRoute({ kind: RouteKind.Signup, defaultEmail: null })}
          className="btn btn-dark rounded-pill"
          style={{
            fontSize: "2rem",
            paddingLeft: "2rem",
            paddingRight: "2rem",
          }}
        >
          Kostenlos Testen
        </Link>
      </div>
    </div>
  );
}

function Landing() {
  return (
    <main
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "32px",
        paddingTop: "32px",
        paddingBottom: "16px",
        fontFamily: '"Source Serif 4", serif',
      }}
    >
      <HeadlineAndDemo />

      <UserStory />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "min(100%, 1024px)",
        }}
      >
        {imageShowCasesFirst}
      </div>

      <HowItWorks />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "min(100%, 1024px)",
        }}
      >
        {imageShowCasesSecond}
      </div>

      <Conclusion />
    </main>
  );
}

export default Landing;
