import React, { useState, ChangeEvent, useEffect, useRef } from "react";
import { deleteCar, postCar } from "./api/car";
import {
  downloadLinkHtml,
  downloadLinkPdf,
  getProtocol,
  postProtocol,
} from "./api/protocol";
import { Car, CarId, Protocol, ProtocolId, ProtocolSigns } from "./types";
import noStoppingLeft from "./no-stopping-left.png";
import noStoppingRight from "./no-stopping-right.png";
import noStoppingLeftRight from "./no-stopping-left-right.png";
import noParkingLeft from "./no-parking-left.png";
import noParkingRight from "./no-parking-right.png";
import noParkingLeftRight from "./no-parking-left-right.png";
import jsQR from "jsqr";
import { QRCodeScanElement } from "./qr-code-scan";
import { postLinkQrCode } from "./api/qr-code";
import { RouteKind, serializeRoute } from "./route";
import { Link, useLocation } from "wouter";

// An random UUID v4:
function randomUuidV4(): string {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

enum CarSyncState {
  SYNCED,
  UPLOADING,
  UPDATING,
  FAILED,
}

enum ProtocolSyncState {
  SYNCED,
  UPLOADING,
  FAILED,
}

function blurOnEnter(event: React.KeyboardEvent<HTMLInputElement>) {
  if (event.key === "Enter") {
    event.currentTarget.blur();
  }
}

type ProtocolEditElementProps = {
  protocolId: ProtocolId;
};

function toLocalTime(date: Date): string {
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${hours}:${minutes}`;
}

function setLocalTime(date: Date, time: string) {
  const [hours, minutes] = time.split(":").map((part) => parseInt(part, 10));
  date.setHours(hours);
  date.setMinutes(minutes);
}

function toLocalDate(date: Date): string {
  const year = date.getFullYear().toString().padStart(4, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
}

function setLocalDate(date: Date, localDate: string) {
  const [year, month, day] = localDate
    .split("-")
    .map((part) => parseInt(part, 10));
  date.setFullYear(year);
  date.setMonth(month - 1);
  date.setDate(day);
}

type SignElementProps = {
  signs: ProtocolSigns;
  setSigns: (signs: ProtocolSigns) => void;
};

function SignElement({ signs, setSigns }: SignElementProps): JSX.Element {
  const incrementSign = (key: keyof ProtocolSigns) => {
    setSigns({
      ...signs,
      [key]: (signs[key] || 0) + 1,
    });
  };

  const decrementSign = (key: keyof ProtocolSigns) => {
    setSigns({
      ...signs,
      [key]: signs[key] && signs[key]! > 0 ? signs[key]! - 1 : 0,
    });
  };

  const rowStyle: React.CSSProperties = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
    padding: "10px",
    borderRadius: "5px",
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
  };

  const incrementDecrementButtonStyle: React.CSSProperties = {
    height: 48,
    width: 48,
    fontSize: 24,
  };

  const signImageArray: [keyof ProtocolSigns, string][] = [
    ["noStoppingSignLeft", noStoppingLeft],
    ["noStoppingSignRight", noStoppingRight],
    ["noStoppingSignLeftRight", noStoppingLeftRight],
    ["noParkingSignLeft", noParkingLeft],
    ["noParkingSignRight", noParkingRight],
    ["noParkingSignLeftRight", noParkingLeftRight],
  ];

  return (
    <div>
      {signImageArray.map(([key, image]) => (
        <div key={key} style={rowStyle}>
          <img
            src={image}
            alt="Schild"
            style={{ maxWidth: "50%", maxHeight: 96 }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 12,
            }}
          >
            <button
              className="btn btn-dark"
              style={incrementDecrementButtonStyle}
              onClick={() => decrementSign(key)}
            >
              -
            </button>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                minWidth: 20,
              }}
            >
              {signs[key] || 0}
            </div>
            <button
              className="btn btn-dark"
              style={incrementDecrementButtonStyle}
              onClick={() => incrementSign(key)}
            >
              +
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}

enum QrCodeLinkState {
  NONE,
  FAILED,
  SUCCESS,
}

export function ProtocolEditElement({
  protocolId,
}: ProtocolEditElementProps): JSX.Element {
  const [protocol, setProtocol] = useState<Protocol | null>(null);
  // TODO: We should show an error if this is on FAILED:
  const [, setProtocolSyncState] = useState<ProtocolSyncState | null>(null);

  const [carSyncStates, setCarSyncStates] = useState<Map<CarId, CarSyncState>>(
    new Map(),
  );

  const shouldScrollToLastCar = React.useRef<boolean>(false);

  const [showQrCodeModal, setShowQrCodeModal] = useState<boolean>(false);
  const [qrCodeLinkState, setQrCodeLinkState] = useState<QrCodeLinkState>(
    QrCodeLinkState.NONE,
  );
  const [, setLocation] = useLocation();

  const [showFinalizeModal, setShowFinalizeModal] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        const protocol = await getProtocol(protocolId);
        setProtocol(protocol);
        setCarSyncStates(
          new Map(
            Object.keys(protocol.carInfos).map((key) => [
              key,
              CarSyncState.SYNCED,
            ]),
          ),
        );
        setProtocolSyncState(ProtocolSyncState.SYNCED);
      } catch (error) {
        console.error("Failed to fetch protocol", error);
        setProtocolSyncState(ProtocolSyncState.FAILED);
      }
    })();
  }, [protocolId]);

  if (protocol == null) {
    return <div className="spinner-border"></div>;
  }
  const isMissingCars = Object.keys(protocol.carInfos ?? {}).length === 0;
  const isMissingAddress = protocol.address == null || protocol.address === "";
  const isMissingDate = protocol.date == null || protocol.date === "";
  const isMissingRecorderName =
    protocol.recorderName == null || protocol.recorderName === "";

  const isMissingData =
    isMissingCars || isMissingAddress || isMissingDate || isMissingRecorderName;

  async function uploadProtocolMetadata(protocolValue?: Protocol | null) {
    // TODO: We should be displaying a spinner and error messages for the uploads here.
    protocolValue = protocolValue ?? protocol;
    if (protocolValue == null) {
      throw new Error("Protocol was null while uploading");
    }

    setProtocolSyncState(ProtocolSyncState.UPLOADING);
    try {
      const protocolWithoutCars = { ...protocolValue, carInfos: {} };
      await postProtocol(protocolId, protocolWithoutCars);
      setProtocolSyncState(ProtocolSyncState.SYNCED);
    } catch (err) {
      console.error("Failed to upload protocol metadata", err);
      setProtocolSyncState(ProtocolSyncState.FAILED);
      throw err;
    }
  }

  const signs = protocol.signs;
  function setSigns(signs: ProtocolSigns) {
    setProtocol((oldProtocol) => {
      if (oldProtocol == null) {
        return null;
      }
      return { ...oldProtocol, signs };
    });

    if (protocol == null) {
      throw new Error("Protocol was null while setting signs");
    }

    const newProtocol = { ...protocol, signs };

    uploadProtocolMetadata(newProtocol);
  }

  async function handleDeleteClick(
    carId: CarId,
    event: React.MouseEvent<HTMLButtonElement>,
  ) {
    event.preventDefault();
    setProtocol((oldProtocol) => {
      if (oldProtocol == null) {
        throw new Error(
          "Change before protocol was loaded should be impossible",
        );
      }

      const newCarInfos = { ...oldProtocol.carInfos };
      delete newCarInfos[carId];

      return {
        ...oldProtocol,
        carInfos: newCarInfos,
      };
    });

    try {
      await deleteCar(protocolId, carId);
    } catch (error) {
      console.error("Failed to delete car", error);
      const previousCarInfo = protocol?.carInfos[carId];
      // TODO: We're trying to roll back the deletion, but it'd be better to display an error or something.
      // TODO: This will change the order of the cars, which is highly confusing.
      if (previousCarInfo != null) {
        setProtocol((oldProtocol) => {
          if (oldProtocol == null) {
            throw new Error(
              "Change before protocol was loaded should be impossible",
            );
          }

          return {
            ...oldProtocol,
            carInfos: {
              ...oldProtocol.carInfos,
              [carId]: previousCarInfo,
            },
          };
        });
      }
    }
  }

  function handleLicensePlateChange(
    carId: CarId,
    event: ChangeEvent<HTMLInputElement>,
  ) {
    const newValue: string = event.target.value;
    setProtocol((oldProtocol) => {
      if (oldProtocol == null) {
        throw new Error(
          "Change before protocol was loaded should be impossible",
        );
      }

      const oldCar = oldProtocol.carInfos[carId];
      if (oldCar == null) {
        console.error("Car disappeared while editing");
        return oldProtocol;
      }

      const newCar = { ...oldCar, licensePlate: newValue };

      return {
        ...oldProtocol,
        carInfos: {
          ...oldProtocol.carInfos,
          [carId]: newCar,
        },
      };
    });
  }

  function handleBrandChange(
    carId: CarId,
    event: ChangeEvent<HTMLInputElement>,
  ) {
    const newValue: string = event.target.value;
    setProtocol((oldProtocol) => {
      if (oldProtocol == null) {
        throw new Error(
          "Change before protocol was loaded should be impossible",
        );
      }

      const oldCar = oldProtocol.carInfos[carId];
      if (oldCar == null) {
        console.error("Car disappeared while editing");
        return oldProtocol;
      }

      const newCar = { ...oldCar, brand: newValue };

      return {
        ...oldProtocol,
        carInfos: {
          ...oldProtocol.carInfos,
          [carId]: newCar,
        },
      };
    });
  }

  function handleColorChange(
    carId: CarId,
    event: ChangeEvent<HTMLInputElement>,
  ) {
    const newValue: string = event.target.value;
    setProtocol((oldProtocol) => {
      if (oldProtocol == null) {
        throw new Error(
          "Change before protocol was loaded should be impossible",
        );
      }

      const oldCar = oldProtocol.carInfos[carId];
      if (oldCar == null) {
        console.error("Car disappeared while editing");
        return oldProtocol;
      }

      const newCar = { ...oldCar, color: newValue };

      return {
        ...oldProtocol,
        carInfos: {
          ...oldProtocol.carInfos,
          [carId]: newCar,
        },
      };
    });
  }

  async function uploadCar(carId: CarId, car: Car) {
    // TODO: This function assumes that it's only running at most once simultaneously for each car.
    // If there is more than one function, we might set the status to SYNCED even though another
    // call is still in-flight. But nothing enforces that the function is only called once at the moment.

    setCarSyncStates((oldStates) => {
      const newState: CarSyncState | null =
        oldStates.get(carId) == null
          ? CarSyncState.UPLOADING
          : CarSyncState.UPDATING;

      const newStates = new Map(oldStates);
      if (newState == null) {
        throw new Error("Should be impossible");
      }
      newStates.set(carId, newState);
      return newStates;
    });

    const newState: CarSyncState = await (async () => {
      try {
        const [newId, newCarValue] = await postCar(protocolId, carId, car);
        if (newId !== carId) {
          throw new Error("Server returned a different ID");
        }

        setProtocol((oldProtocol) => {
          if (oldProtocol == null) {
            // We're never setting the protocol to null after it's been loaded, so this should be
            // impossible.
            throw new Error("Protocol was null while uploading");
          }

          const oldCarValue = oldProtocol.carInfos[carId];
          if (oldCarValue == null) {
            console.error("Car disappeared locally after upload");
            return oldProtocol;
          }

          const mergedCarValue: Car = {
            image: newCarValue.image ?? oldCarValue.image,
            licensePlate: newCarValue.licensePlate ?? oldCarValue.licensePlate,
            brand: newCarValue.brand ?? oldCarValue.brand,
            color: newCarValue.color ?? oldCarValue.color,
          };

          const newProtocol: Protocol = {
            ...oldProtocol,
            carInfos: {
              ...oldProtocol.carInfos,
              [carId]: mergedCarValue,
            },
          };
          return newProtocol;
        });

        return CarSyncState.SYNCED;
      } catch (error) {
        console.error("Upload failed", error);
        return CarSyncState.FAILED;
      }
    })();

    setCarSyncStates((oldStates) => {
      const newStates = new Map(oldStates);
      newStates.set(carId, newState);
      return newStates;
    });
  }

  function handleCapture(event: ChangeEvent<HTMLInputElement>) {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const maxWidth = 800; // Define the maximum width you want
          const scaleFactor = maxWidth / img.width;
          canvas.width = maxWidth;
          canvas.height = img.height * scaleFactor;
          const ctx = canvas.getContext("2d");
          if (ctx) {
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            const resizedImage = canvas.toDataURL("image/jpeg", 0.8); // You can adjust the quality here (0.8 = 80%)

            const newId = randomUuidV4();
            const newCar: Car = {
              image: resizedImage,
              licensePlate: null,
              brand: null,
              color: null,
            };

            setProtocol((oldProtocol) => {
              if (oldProtocol == null) {
                throw new Error(
                  "Change before protocol was loaded should be impossible",
                );
              }

              return {
                ...oldProtocol,
                carInfos: {
                  ...oldProtocol.carInfos,
                  [newId]: newCar,
                },
              };
            });

            // Scroll to the last car:
            shouldScrollToLastCar.current = true;

            uploadCar(newId, newCar);
          }
        };
      };
      reader.readAsDataURL(file);
    }
  }

  function handleQrCodeCapture(event: ChangeEvent<HTMLInputElement>) {}

  const buttonStyle: React.CSSProperties = {
    position: "fixed",
    bottom: "20px",
    right: "20px",
    width: "60px",
    height: "60px",
    borderRadius: "50%",
    backgroundColor: "#007bff",
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    border: "none",
    cursor: "pointer",
    fontSize: "32px",
  };

  return (
    <div
      style={{
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 4,
        paddingRight: 4,
      }}
    >
      <h2
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        Aufstellprotokoll
      </h2>

      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <input
          type="text"
          placeholder="Adresse"
          value={protocol.address ?? ""}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setProtocol({ ...protocol, address: e.target.value });
          }}
          onBlur={() => {
            uploadProtocolMetadata();
          }}
          style={{
            padding: "10px",
            borderRadius: "4px",
            border: "1px solid #ccc",
            width: "100%",
          }}
        />

        <div style={{ display: "flex", flexDirection: "row", gap: 16 }}>
          <input
            type="date"
            placeholder="Datum"
            value={
              protocol.date != null ? toLocalDate(new Date(protocol.date)) : ""
            }
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              try {
                const newDate = new Date(protocol.date ?? new Date());
                setLocalDate(newDate, e.target.value);
                setProtocol({ ...protocol, date: newDate.toISOString() });
              } catch (error) {
                console.error("Failed to parse date", error);
              }
            }}
            onBlur={() => {
              uploadProtocolMetadata();
            }}
            style={{
              padding: "10px",
              borderRadius: "4px",
              border: "1px solid #ccc",
              width: "100%",
            }}
          />
          <input
            type="time"
            value={
              protocol.date != null ? toLocalTime(new Date(protocol.date)) : ""
            }
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              try {
                const newDate = new Date(protocol.date ?? new Date());
                setLocalTime(newDate, e.target.value);
                setProtocol({ ...protocol, date: newDate.toISOString() });
              } catch (error) {
                console.error("Failed to parse date", error);
              }
            }}
            onBlur={() => {
              uploadProtocolMetadata();
            }}
            style={{
              padding: "10px",
              borderRadius: "4px",
              border: "1px solid #ccc",
              width: "100%",
            }}
          />
        </div>

        <input
          type="text"
          placeholder="Protokollant"
          value={protocol.recorderName ?? ""}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setProtocol({ ...protocol, recorderName: e.target.value });
            uploadProtocolMetadata();
          }}
          onBlur={() => {
            uploadProtocolMetadata();
          }}
          style={{
            padding: "10px",
            borderRadius: "4px",
            border: "1px solid #ccc",
            width: "100%",
          }}
        />

        <div>
          <SignElement signs={signs} setSigns={setSigns} />
        </div>

        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          {Object.keys(protocol.carInfos).length === 0 && (
            <div className="alert alert-warning">Noch keine Fahrzeuge</div>
          )}
          {Object.entries(protocol.carInfos).map(([carId, car]) => (
            <div
              key={carId}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "8px",
                backgroundColor: "#f9f9f9",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 10,
                  marginRight: 10,
                }}
              >
                <img
                  src={car.image}
                  alt="Photo eines Autos"
                  style={{
                    width: "120px",
                    height: "120px",
                    borderRadius: "4px",
                    objectFit: "cover",
                  }}
                />
                <button
                  className="btn btn-danger"
                  onClick={(e) => handleDeleteClick(carId, e)}
                >
                  Löschen
                </button>
              </div>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  gap: 4,
                }}
              >
                <input
                  type="text"
                  placeholder="Nummernschild"
                  value={car.licensePlate ?? ""}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    handleLicensePlateChange(carId, e);
                  }}
                  onBlur={() => {
                    uploadCar(carId, car);
                  }}
                  onKeyPress={blurOnEnter}
                  style={{
                    padding: "10px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    width: "100%",
                  }}
                />
                <input
                  type="text"
                  placeholder="Automarke"
                  value={car.brand ?? ""}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    handleBrandChange(carId, e);
                  }}
                  onBlur={() => {
                    uploadCar(carId, car);
                  }}
                  onKeyPress={blurOnEnter}
                  style={{
                    padding: "10px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    width: "100%",
                  }}
                />
                <input
                  type="text"
                  placeholder="Farbe"
                  value={car.color ?? ""}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    handleColorChange(carId, e);
                  }}
                  onBlur={() => {
                    uploadCar(carId, car);
                  }}
                  onKeyPress={blurOnEnter}
                  style={{
                    padding: "10px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    width: "100%",
                  }}
                  ref={
                    shouldScrollToLastCar.current
                      ? (node) => {
                          if (node) {
                            node.scrollIntoView();
                            shouldScrollToLastCar.current = false;
                          }
                        }
                      : null
                  }
                />

                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    pointerEvents: "none",
                    opacity:
                      carSyncStates.get(carId) === CarSyncState.UPDATING
                        ? 1
                        : 0,
                    transitionProperty: "opacity",
                    // The delay should only be there when we transition from not-shown to shown:
                    // It's there to avoid flickering in case the upload is reasonably fast.
                    transitionDelay:
                      carSyncStates.get(carId) === CarSyncState.UPDATING
                        ? "0.5s"
                        : "0s",
                    transitionDuration: "0.3s",
                  }}
                >
                  <div className="spinner-border spinner-border-sm" />
                </div>

                {(() => {
                  const state = carSyncStates.get(carId);
                  switch (state) {
                    case CarSyncState.SYNCED:
                      return null;
                    case CarSyncState.UPLOADING:
                      // A spinner in the foreground:
                      return (
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "rgba(255, 255, 255, 0.7)",
                            pointerEvents: "none",
                          }}
                        >
                          <div className="spinner-border" />
                        </div>
                      );
                    case CarSyncState.UPDATING:
                      // We always keep the UPDATING spinner in the DOM so that opacity transitions work.
                      return null;
                    case CarSyncState.FAILED:
                    case undefined:
                      return (
                        <div className="alert alert-danger">
                          Upload fehlgeschlagen.
                          <button
                            className="btn btn-dark"
                            onClick={() => {
                              uploadCar(carId, car);
                            }}
                          >
                            Wiederholen
                          </button>
                        </div>
                      );
                    default: {
                      const exhaustive: never = state;
                      throw new Error(`Unreachable: ${exhaustive}`);
                    }
                  }
                })()}
              </div>
            </div>
          ))}
          {(() => {
            switch (qrCodeLinkState) {
              case QrCodeLinkState.NONE:
                return null;
              case QrCodeLinkState.FAILED:
                return (
                  <div className="alert alert-danger">
                    QR-Code-Verbindung fehlgeschlagen
                  </div>
                );
              case QrCodeLinkState.SUCCESS:
                return (
                  <div className="alert alert-success">
                    QR-Code-Verbindung erfolgreich
                  </div>
                );
              default: {
                const exhaustive: never = qrCodeLinkState;
                throw new Error(`Unreachable: ${exhaustive}`);
              }
            }
          })()}
          <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
            <button
              className="btn btn-dark"
              onClick={() => {
                setShowQrCodeModal(true);
              }}
              style={{ width: "100%" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-qr-code"
                viewBox="0 0 16 16"
              >
                <path d="M2 2h2v2H2z" />
                <path d="M6 0v6H0V0zM5 1H1v4h4zM4 12H2v2h2z" />
                <path d="M6 10v6H0v-6zm-5 1v4h4v-4zm11-9h2v2h-2z" />
                <path d="M10 0v6h6V0zm5 1v4h-4V1zM8 1V0h1v2H8v2H7V1zm0 5V4h1v2zM6 8V7h1V6h1v2h1V7h5v1h-4v1H7V8zm0 0v1H2V8H1v1H0V7h3v1zm10 1h-1V7h1zm-1 0h-1v2h2v-1h-1zm-4 0h2v1h-1v1h-1zm2 3v-1h-1v1h-1v1H9v1h3v-2zm0 0h3v1h-2v1h-1zm-4-1v1h1v-2H7v1z" />
                <path d="M7 12h1v3h4v1H7zm9 2v2h-3v-1h2v-1z" />
              </svg>{" "}
              QR-Code verbinden
            </button>
            <a
              href={downloadLinkHtml(protocolId)}
              target="_blank"
              className="btn btn-dark"
              style={{ width: "100%" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-eye"
                viewBox="0 0 16 16"
              >
                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
              </svg>{" "}
              Anzeigen
            </a>
            <button
              className="btn btn-dark"
              style={{ width: "100%" }}
              onClick={() => {
                setShowFinalizeModal(true);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-check"
                viewBox="0 0 16 16"
              >
                <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
              </svg>{" "}
              Abschließen
            </button>
          </div>

          <div>
            <label htmlFor="cameraInput" style={buttonStyle}>
              +
            </label>
            <input
              id="cameraInput"
              type="file"
              accept="image/*"
              capture="environment"
              onChange={handleCapture}
              style={{ display: "none" }}
            />
          </div>
        </div>
      </div>
      {showQrCodeModal && (
        <div className="modal fade show d-block" tabIndex={-1} role="dialog">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Mit QR-Code verbinden</h5>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => setShowQrCodeModal(false)}
                ></button>
              </div>
              <div
                className="modal-body"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <QRCodeScanElement
                  onScanned={async (qrCodeUrl) => {
                    try {
                      // Get the part after the last slash:
                      const qrCodeUuid = qrCodeUrl.split("/").pop();
                      if (qrCodeUuid == null) {
                        setQrCodeLinkState(QrCodeLinkState.FAILED);
                        return;
                      }

                      await postLinkQrCode(qrCodeUuid, protocolId);
                      setQrCodeLinkState(QrCodeLinkState.SUCCESS);
                    } catch (err) {
                      setQrCodeLinkState(QrCodeLinkState.FAILED);
                    } finally {
                      setShowQrCodeModal(false);
                    }
                  }}
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    setShowQrCodeModal(false);
                  }}
                >
                  Abbrechen
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showFinalizeModal && (
        <div className="modal fade show d-block" tabIndex={-1} role="dialog">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Protokoll abschließen</h5>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => setShowFinalizeModal(false)}
                ></button>
              </div>
              {isMissingData && (
                <div
                  className="modal-body"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div className="text-danger">Fehlende Daten:</div>
                  <ul className="text-danger">
                    {isMissingRecorderName && <li>Protokollant</li>}
                    {isMissingDate && <li>Datum</li>}
                    {isMissingCars && <li>Fahrzeuge</li>}
                    {isMissingAddress && <li>Adresse</li>}
                  </ul>
                  Trotzdem abschließen?
                </div>
              )}
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowFinalizeModal(false)}
                >
                  Abbrechen
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    setLocation(serializeRoute({ kind: RouteKind.Home }));
                  }}
                >
                  Abschließen
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
